import { useContext } from 'react';

import { MainLayOut } from '../../layouts';
import { ThemeContext } from '../../contexts';

import { useNavigateTo } from '../../hooks';
import { privateRoutes } from '../../routes';


import  style from './edit.module.scss';



const ModifySubscriber = () => {

    const { theme } = useContext(ThemeContext);

    const [ goto ] = useNavigateTo();

    return (
        <MainLayOut>
            <div className={style.title_container}>
                <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=>goto(privateRoutes.SUBSCRIBERS)}>
                    Suscriptores
                    <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Editar suscriptor</span>
                </p>
            </div>

            <div className={style.blocks_container}>
            </div>
        </MainLayOut>
    )
};

export default ModifySubscriber;
