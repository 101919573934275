import { useContext } from 'react';

import { MainLayOut } from '../../layouts';
import { ThemeContext } from '../../contexts';

import { AddIcon, PublicationIcon, EditIcon } from '../../assets';

import { privateRoutes } from '../../routes';
import { useNavigateTo } from '../../hooks';

import  style from './subs.module.scss';



const Subscribers = () => {

    const { theme } = useContext(ThemeContext);

    const [ goto ] = useNavigateTo();


    return (
        <MainLayOut>
            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Suscriptores</p>
            </div>

            <div className={style.blocks_container}>

                <div onClick={()=>goto(privateRoutes.NEWSUBSCRIBER)} className={style.block} style={{background: `${theme.background}`}}>
                    <AddIcon width='26px' height='26px' fill={theme.strokeColor02} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nuevo suscriptor</p>
                </div>


                <div onClick={()=>goto(privateRoutes.LISTOFSUBSCRIBERS)} className={style.block} style={{background: `${theme.background}`}}>
                    <PublicationIcon width='26px' height='26px' fill={theme.strokeColor06} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Lista de suscriptores</p>
                </div>
                
                
                <div onClick={()=>goto(privateRoutes.MODIFYSUBSCRIBER)} className={style.block} style={{background: `${theme.background}`}}>
                    <EditIcon width='26px' height='26px' fill={theme.strokeColor01} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Editar suscriptor</p>
                </div>

            </div>
            
        </MainLayOut>
    )
};

export default Subscribers;
