import { Route, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './routes';

import { Authguard, RouteNotFound } from './guards';
import { ModalMessage } from './components';
import { Login } from './sections';

import { Subscribers, NewSubscriber, SubscribersList, ModifySubscriber, VerifyMailAccount } from './sections';


const App = () => {
    return (
        <>
            <ModalMessage />
            <RouteNotFound path={publicRoutes.HOME}>
                <Route path={publicRoutes.LOGIN} element={<Login />}/>

                <Route element={<Authguard />}>
                    <Route path={publicRoutes.HOME} element={ <Navigate to={privateRoutes.SUBSCRIBERS} /> } />
                    
                    <Route path={privateRoutes.SUBSCRIBERS} element={<Subscribers />}/>
                    <Route path={privateRoutes.NEWSUBSCRIBER} element={<NewSubscriber />}/>
                    <Route path={privateRoutes.LISTOFSUBSCRIBERS} element={<SubscribersList />}/>
                    <Route path={privateRoutes.MODIFYSUBSCRIBER} element={<ModifySubscriber />}/>
                </Route>
                
                <Route path={publicRoutes.VERIFYMAILACCOUNT} element={<VerifyMailAccount />}/>

            </RouteNotFound>
        </>
    )
};

export default App;
